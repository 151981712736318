import { Button, TextField, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import theme from '../theme';

interface State {
  email: string;
  message: string;
  name: string;
  tel: string;
  loading: boolean;
}

class ContactPage extends React.Component<{}, State> {
  public state = {
    email: '',
    message: '',
    name: '',
    tel: '',
    loading: false,
  };

  public updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  }

  public updateMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ message: e.target.value });
  }

  public updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  }

  public updateTel = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tel: e.target.value });
  }

  public setLoading = (loading: boolean) => {
    this.setState({ loading });
  }

  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setLoading(true);

    const data = {
      subject: 'Prise de contact CAMPUS.LFAI.CO',
      name:
        this.state.name && this.state.name.length
          ? this.state.name
          : 'Aucun nom',
      email: this.state.email,
      content: `${this.state.message}\n\nTEL: ${this.state.tel}`,
    };

    grecaptcha.ready(() => {
      grecaptcha
        .execute('6Lf6llwUAAAAAFaJjib6ZuijNk42xhcwHcfM3Cir', {
          action: 'sendEmail',
        })
        .then((token: string) => {
          fetch('https://api.lfai.co/api/emails/contact/', {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data, recaptchaToken: token }),
          }).then(() => {
            this.setLoading(false);
            navigate('/');
          });
        });
    });

    return false;
  }

  public render() {
    return (
      <Layout>
        {typeof grecaptcha !== 'undefined' ? null : (
          <Helmet>
            <script
              src="https://www.google.com/recaptcha/api.js?render=6Lf6llwUAAAAAFaJjib6ZuijNk42xhcwHcfM3Cir"
              defer
            />
          </Helmet>
        )}
        <div style={{ marginTop: '70px', textAlign: 'center' }}>
          <div
            style={{ maxWidth: '600px', margin: '0 auto', marginTop: '40px' }}
          >
            <form style={{ padding: '20px' }} onSubmit={this.onSubmit}>
              <Typography
                variant="display1"
                style={{
                  marginTop: '0px',
                  marginBottom: '30px',
                  color: theme.palette.blue.main,
                }}
              >
                Contact
              </Typography>
              <TextField
                label="Email"
                required
                margin="normal"
                placeholder="mail@entreprise.com"
                value={this.state.email}
                onChange={this.updateEmail}
                fullWidth
              />
              <TextField
                label="Nom"
                margin="normal"
                placeholder=" John Doe"
                value={this.state.name}
                onChange={this.updateName}
                fullWidth
              />
              <TextField
                label="Numéro de téléphone"
                type="tel"
                margin="normal"
                placeholder="0XXXXXXXXX"
                value={this.state.tel}
                onChange={this.updateTel}
                fullWidth
              />
              <TextField
                label="Message"
                margin="normal"
                required
                placeholder="Message"
                style={{ overflow: 'hidden' }}
                multiline
                fullWidth
                value={this.state.message}
                onChange={this.updateMessage}
              />
              <div style={{ marginTop: '20px' }}>
                <Button
                  disabled={
                    this.state.loading ||
                    !this.state.email.length ||
                    !this.state.message.length
                  }
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {this.state.loading ? 'Envoi en cours' : 'Envoyer'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
